import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
  const chatRoomElement = document.getElementById('inspection-chat-room')
  if (!chatRoomElement) {
    return
  }

  const roomId = chatRoomElement.getAttribute('data-inspection-id')

  const chatChannel = consumer.subscriptions.create({ channel: "InspectionChatChannel", room: `inspection_chat_${roomId}` }, {
    connected() {
      console.log("Connected to the chat room " + `inspection_chat_${roomId}`)
    },

    disconnected() {
      console.log("Disconnected from the chat room " + `inspection_chat_${roomId}`)
    },

    received(data) {
      debugger
      const messageContainer = document.getElementById('messages')
      messageContainer.innerHTML += data.message // Data received from the server
    },
  });
});
